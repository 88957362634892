.OptionsPickerItem-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    margin: 5px 5px;
    border-radius: 20px;
    border: #97a6f2 solid 2px;
}

.OptionsPickerItem-container.active {
    background-color: #97a6f2;
}

.OptionsPickerItem-container.disabled {
    background-color: #f6f5f6;
    border-color: #f6f5f6;
}

.OptionsPickerItem-container {
    cursor: pointer;
}

.OptionsPickerItem-span {
    text-align: center;
    color: #97a6f2;
    font-weight: 500;
}

.OptionsPickerItem-span.active {
    color: white;
}

.OptionsPickerItem-span.disabled {
    color: #77767d;
}
