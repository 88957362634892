.SettingsContainer-container {
    width: 100%;
    position: relative;
    margin-top: 20px;
    min-width: 0;
}

.SettingsContainer-title {
    font-size: 12px;
    font-weight: 500;
    color: #97a6f2;
}

.SettingsContainer-body {
    box-sizing: border-box;
    width: 90%;
    background-color: white;
    border-radius: 12px;
    flex-grow: 1;
    margin: 10px 0;
}
