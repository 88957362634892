.InterestsFilter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0 0 0;
    max-width: 50%;
}

.InterestsFilter-title-span {
    color: #201f24;
    font-weight: 500;
}

.InterestsFilter-clear-interests-span {
    color: #97a6f2;
    text-decoration: underline;
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
}

@media (max-width: 800px) {
    .InterestsFilter-container {
        max-width: 90%;
    }
}
