@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.Message-font {
    font-size: 16px;
    font-weight: 400;
    text-align: start;
    white-space: pre-line;
    word-break: break-all;
}

.Message-container {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    max-width: 650px;
    width: 100%;
    align-self: center;
}

.Message-container.user {
    align-items: flex-end;
}

.Message-container.partner {
    align-items: flex-start;
}

.Message-container.system {
    align-items: center;
}

.Message-container.about-me {
    align-items: center;
}

.Message-common-bubble {
    display: inline-block;
    position: relative;
    min-width: 60px;
    max-width: 70%;
    justify-content: flex-start;
    padding: 0 16px;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px 0;
    margin: 0 10px 10px 10px;
}

.Message-common-bubble.hoverable {
    cursor: pointer;
}

.Message-user-bubble {
    background: #97a6f2;
}

.Message-partner-bubble {
    background: white;
}

.Message-system-bubble {
    background: transparent;
    box-shadow: none;
}

.Message-about-me-bubble {
    background: white;
}

.Message-text-bubble {
}

.Message-image-bubble {
    padding: 0;
}
