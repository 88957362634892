.MessageReply-container {
    display: flex;
    flex-grow: 1;
    height: 60px;
    padding: 10px 0;
    box-sizing: border-box;
    /*
        we need this negative margin to make message with reply look thinner  because we wrap message's text in <p>
        and it has default margin 1em and with given margin it looks big
    */
    margin-bottom: -1em;
}

.MessageReply-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    min-width: 0;
    flex-direction: row;
    border-left-style: solid;
    border-left-width: 2px;
}

.MessageReply-content.user {
    border-left-color: white;
}

.MessageReply-content.partner {
    border-left-color: #97a6f2;
}

.MessageReply-container-spans {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    margin-left: 10px;
    min-width: 0;
}

.MessageReply-image {
    height: 100%;
    width: auto;
    object-fit: contain;
    border-radius: 5px;
    margin-left: 10px;
}

.MessageReply-content-name {
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.MessageReply-content-name.user {
    color: white;
}

.MessageReply-content-name.partner {
    color: #201f24;
}

.MessageReply-content-body {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.MessageReply-content-body.user {
    color: white;
}

.MessageReply-content-body.partner {
    color: #201f24;
}
