.EditableSettingsInputActionButton-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    width: 30px;
    padding: 5px 5px 5px 15px;
    box-sizing: content-box;
    max-width: 10%;
}

.EditableSettingsInputActionButton-icon {
    color: #77767d;
}

.EditableSettingsInputActionButton-icon:hover {
    transform: scale(1.1);
}
