.PreviewContent-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
    min-width: 0;
    margin-right: 10px;
}

.PreviewContent-username {
    color: white;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
}

.PreviewContent-last-message-text {
    flex-direction: row;
    color: #77767d;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PreviewContent-last-message-text.typing {
    color: #97a6f2;
}

.PreviewContent-last-message-text-sender {
    flex-shrink: 0;
    color: #97a6f2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PreviewContent-last-message-text-sender.draft {
    color: #de6560;
}

.PreviewContent-last-message-text.muted {
    color: #93919d;
}
