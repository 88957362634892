.MessageTimestamp-sent-at-text {
    color: #93919d;
    font-size: 12px;
    margin: 0 2px 0 0;
}

.MessageTimestamp-user {
    color: #cbd3f9;
}

.MessageTimestamp-partner {
    color: #93919d;
}
