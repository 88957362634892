.RecordButton-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    width: 52px;
    height: 52px;
    flex-shrink: 0;
}

.RecordButton-container:hover {
    cursor: pointer;
}

.RecordButton-container:active {
    transform: scale(0.95);
}

.RecordButton-icon {
    color: #93919d;
    font-size: 18px;
}

.RecordButton-icon.send {
    color: #97a6f2;
}
