.ExtraButton-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-grow: 1;
    height: 50px;
    flex-shrink: 1;
}

.ExtraButton-container:hover {
    cursor: pointer;
}

.ExtraButton-middle-div {
    border-style: solid;
    border-color: #f3f3f3;
    border-width: 0 1px 0 1px;
}

.ExtraButton-title-text {
    margin-left: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media (max-width: 900px) {
    .ExtraButton-title-text {
        display: none;
    }
}
