.ChatAdminGptTemplate-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    overflow-x: scroll;
    max-width: 650px;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
}

.ChatAdminGptTemplate-paste-button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    color: #405cea;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
}

.ChatAdminGptTemplate-paste-button:hover {
    color: #052ae3;
}
