.UserSettings-container {
    background-color: #2d2b31;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    height: 75px;
    padding: 20px;
    min-width: 0;
    margin-bottom: 1px;
    box-sizing: border-box;
}

.UserSettings-username-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    min-width: 0;
}

.UserSettings-username-span {
    color: white;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
}

.UserSettings-settings-icon {
    color: #77767d;
    font-size: 25px;
}

.UserSettings-settings-icon:hover {
    transform: scale(1.1);
    opacity: 0.75;
    cursor: pointer;
}

.UserSettings-settings-icon:active {
    transform: scale(0.95);
}
