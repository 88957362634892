@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.SettingsGenderContainer-container {
    display: flex;
    flex-direction: column;
    min-height: 75px;
    width: 100%;
    margin-bottom: 20px;
    min-width: 0;
}

.SettingsGenderContainer-title {
    font-size: 12px;
    font-weight: 500;
    color: #97a6f2;
}

.SettingsGenderContainer-select {
    height: 50px;
    border-radius: 12px;
    margin-top: 10px;
    padding: 15px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    text-align: start;
    color: #201f24;
    font-size: 16px;
    outline: none;
    border: none;
    max-width: 90%;
    background-color: white;
}
