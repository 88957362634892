@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.ChatPreview-container {
    display: flex;
    flex-direction: row;
    height: 40px;
    padding: 20px;
    min-width: 0;
}

.ChatPreview-border-div {
    border-top-width: 1px;
    border-top-color: #2d2b31;
    border-top-style: solid;
    margin-left: 80px;
}

.ChatPreview-container:hover {
    cursor: pointer;
    background-color: #2d2b31;
}

.ChatPreview-container:hover > .ExplorePreview-icon-div-hovered {
    background-color: #201f24;
}
