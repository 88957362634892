.MessageText-p {
    margin-top: 15px;
}

.MessageText-user-text {
    color: white;
}

.MessageText-partner-text {
    color: #201f24;
}
