@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.MessageInput-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: center;
    max-width: 650px;
    min-width: 0;
}

.MessageInput-input {
    flex-grow: 1;
    max-height: 200px;
    min-height: 50px;
    overflow-y: scroll;
    background-color: white;
    border-radius: 12px;
    padding: 15px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    text-align: start;
    color: #201f24;
    box-sizing: border-box;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.MessageInput-input::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}

.MessageInput-input.active-preview {
    border-radius: 0 0 12px 12px;
}

.MessageInput-input:focus {
    outline: none;
}

.MessageInput-placeholder-text {
    color: #93919d;
}
