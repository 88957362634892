.AttachButton-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    width: 52px;
    height: 52px;
    flex-shrink: 0;
}

.AttachButton-container:hover {
    cursor: pointer;
}

.AttachButton-container:active {
    transform: scale(0.95);
}
