.ExploreBurgerMenuTrigger-container {
    flex-grow: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.ExploreBurgerMenuTrigger-icon:active {
    transform: scale(0.95);
}

.ExploreBurgerMenuTrigger-icon-div {
    margin-top: 20px;
    margin-right: 20px;
}

@media (min-width: 800px) {
    .ExploreBurgerMenuTrigger-container {
        display: none;
    }
}
