.ChatHistory-container {
    display: flex;
    flex-grow: 1;
    width: 100%;
    flex-direction: column-reverse;
    align-self: center;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.ChatHistory-container::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}
