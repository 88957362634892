.ChatProfileInfoSection-container {
    display: flex;
    max-height: 30%;
    /* balance paddings */
    max-width: calc(100% - 50px);
    width: 100%;
    flex-direction: column;
    padding: 25px;
    margin-top: 25px;
    border-radius: 12px;
    background-color: white;
}

.ChatProfileInfoSection-title {
    font-size: 14px;
    font-weight: 500;
    color: #97a6f2;
    margin-bottom: 8px;
}

.ChatProfileInfoSection-info {
    font-size: 16px;
    font-weight: 400;
    overflow-y: scroll;
}

@media (max-width: 800px) {
    .ChatProfileInfoSection-container {
        padding-block: 10px;
    }
}
