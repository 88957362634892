.ChatProfileMedia-container {
    display: inline-block;
    margin-top: 25px;
}

.ChatProfileMedia-img {
    object-fit: cover;
    border-radius: 10px;
    width: calc(25% - 2px);
    margin: 1px;
    height: 200px;
    cursor: pointer;
}

.ChatProfileMedia-img:hover {
    opacity: 0.9;
}
