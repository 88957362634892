.ChatProfileOnlineStatus-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 8px;
    height: auto;
    min-width: 0;
}

.ChatProfileOnlineStatus-status {
    font-size: 14px;
    color: #97a6f2;
    font-weight: 500;
}
