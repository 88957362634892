@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.ChatList-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.ChatList-container::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}

.ChatList-bottom-element-div {
    opacity: 0;
}
