.MessageReaction-container {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 16px;
    gap: 5px;
    background: white;
    padding: 2px 5px;
}

.MessageReaction-container.mine {
}

.MessageReaction-container.partner {
}

.MessageReaction-emoji {
    height: 18px;
}
