@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.ChatFooter-container {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    margin: 0 10px 40px 10px;
    padding-top: 10px;
}

.ChatFooter-toolbar-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
}
