@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.FancyModal-container {
    display: block;
    min-width: 250px;
    min-height: 170px;
    background: white;
    z-index: 10;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    padding: 20px;
    color: #201f24;
}

.FancyModal-header-title {
    margin: 0;
    padding: 10px;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    white-space: pre-wrap;
    word-break: break-word;
}

.FancyModal-description-span {
    margin: 10px 0;
    font-size: 16px;
    text-align: end;
    font-weight: 400;
    white-space: pre-wrap;
    word-break: break-word;
    color: #77767d;
}

.FancyModal-actions-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.FancyModal-close-span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #97a6f2;
    font-size: 16px;
    padding-top: 20px;
    font-weight: 500;
    cursor: pointer;
}
