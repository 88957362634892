.MessageReactions-container {
    display: flex;
    flex-direction: row;
    margin: 0 10px 10px 10px;
    gap: 10px;
}

.MessageReactions-container.me {
    align-self: flex-end;
}

.MessageReactions-container.partner {
    align-self: flex-start;
}
