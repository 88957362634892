.Explore-Content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 170px;
}

.Explore-Content-ring-loader-div {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.Explore-Content-title {
    font-size: 24px;
    font-weight: 500;
    margin: 35px 0 12px 0;
    color: #201f24;
    text-align: center;
}

.Explore-Content-subtitle {
    color: #77767d;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
}
