.SettingsItem-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    cursor: pointer;
    padding: 16px;
    justify-content: center;
    border-radius: 12px;
}

.SettingsItem-container:hover {
    background-color: #f6f5f6;
}

.SettingsItem-container.disabled {
    cursor: default;
    background-color: transparent;
}

.SettingsItem-body {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.SettingsItem-bottom-tip {
    font-size: 12px;
    color: #77767d;
    margin-top: 10px;
}
