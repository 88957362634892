.EditableSettingsInputLimit-limit {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 1px;
    border-radius: 10px;
    right: 0;
    bottom: -5px;
    background-color: white;
    min-width: 20px;
    border-style: solid;
    border-color: #f3f3f3;
    border-width: 3px;
    font-size: 10px;
    font-weight: 500;
}

.EditableSettingsInputLimit-limit.reached {
    color: indianred;
}
