.ChatAdminTemplates-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    overflow-x: scroll;
    max-width: 650px;
    width: 100%;
    height: 50px;
    flex-grow: 0;
    flex-shrink: 0;
}
