.SettingsItemNavigate-title {
    font-size: 16px;
    color: black;
    margin-left: 10px;
}

.SettingsItemNavigate-chevron {
    color: black;
    font-size: 20px;
}
