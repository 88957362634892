.PreviewTimestamp-container {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
}

.PreviewTimestamp-unread-messages-count-div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #97a6f2;
    border-radius: 8px;
    align-self: center;
}

.PreviewTimestamp-unread-messages-count-div.muted {
    background: #93919d;
}

.PreviewTimestamp-unread-messages-count-span {
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 3px 10px;
}

.PreviewTimestamp-hide {
    display: none;
}
