@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.Toast-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 12px;
    bottom: 20px;
    background-color: white;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px 0;
    z-index: 1002;
    transform: translateX(110%);
    transition: transform 0.5s ease;
}

.Toast-container.show {
    animation-name: slide-in;
    animation-duration: 3s;
    animation-delay: 0.5s;
}

@keyframes slide-in {
    from {
        transform: translateX(110%);
    }
    40% {
        transform: translateX(0%);
    }
    60% {
        transform: translateX(0%);
    }
    to {
        transform: translateX(110%);
    }
}

.Toast-title {
    color: #201f24;
    font-size: 16px;
    font-weight: 500;
    white-space: pre-wrap;
    word-break: break-word;
}
