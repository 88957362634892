.ChatProfileUsername-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 20px;
    height: auto;
    min-width: 0;
}

.ChatProfileUsername-text {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}
