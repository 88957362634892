.ChatAdminTemplate-span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    height: 100%;
}

.ChatAdminTemplate-span:hover {
    color: #97a6f2;
}
