.SideMenu-container {
    background-color: #201f24;
    max-width: 350px;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
}

@media (max-width: 800px) {
    .SideMenu-container {
        position: fixed;
        left: 0;
        top: 0;
        max-width: 100vw;
        z-index: 1000;
    }

    .SideMenu-container.hide {
        transform: translate3d(-100vw, 0, 0);
    }

    .SideMenu-container.show {
        transform: translate3d(0vw, 0, 0);
        overflow: hidden;
    }
}
