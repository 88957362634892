.PageHeader-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 0;
    height: 75px;
    flex-shrink: 0;
    width: 100%;
    background-color: white;
    padding: 20px;
}
