.SettingsAvatar-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    align-self: center;
    margin: 40px 0;
    height: 140px;
}

.SettingsAvatar-container.uploading {
    pointer-events: none;
    opacity: 0.25;
}

.SettingsAvatar-avatar-change-div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 5px;
    bottom: 5px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #8c9eeb;
    border-style: solid;
    border-width: 2px;
    border-color: #f3f3f3;
    cursor: pointer;
}

.SettingsAvatar-avatar-change-div:hover > .SettingsAvatar-avatar-change-icon-hovered {
    transform: scale(1.1);
}

.SettingsAvatar-avatar-change-icon {
    width: 15px;
    height: 15px;
    color: white;
}
