.BurgerMenuTrigger-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
}

.BurgerMenuTrigger-icon:hover {
    transform: scale(1.05);
}

.BurgerMenuTrigger-icon:active {
    transform: scale(0.95);
}

@media (min-width: 800px) {
    .BurgerMenuTrigger-container {
        display: none;
    }
}
