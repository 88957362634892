@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.ImageModal-backdrop {
    /* Use !important to override original modal background color */
    background-color: rgba(0, 0, 0, 0.75) !important;
}

.ImageModal-container {
    z-index: 10;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ImageModal-image {
    object-fit: contain;
    border-radius: 16px;
    max-height: 80vh;
    max-width: 80vw;
    min-height: 40vh;
    min-width: 40vw;
}

.ImageModal-caption {
    font-size: 16px;
    font-weight: 400;
    color: white;
    margin-top: 20px;
    max-height: 15vh;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.ImageModal-caption::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}
