.MessagePreview-container {
    display: flex;
    flex-grow: 1;
    height: 60px;
    padding: 10px 15px;
    box-sizing: border-box;
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.MessagePreview-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    min-width: 0;
    flex-direction: row;
    border-left-style: solid;
    border-left-color: #97a6f2;
    border-left-width: 2px;
}

.MessagePreview-img {
    height: 100%;
    width: auto;
    object-fit: contain;
    border-radius: 5px;
    margin-left: 10px;
}

.MessagePreview-spans-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    margin-left: 10px;
    min-width: 0;
}

.MessagePreview-span-title {
    font-weight: 500;
    color: #97a6f2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.MessagePreview-span-body {
    color: #201f24;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.MessagePreview-close-icon {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 25px;
    color: #77767d;
    align-self: center;
}

.MessagePreview-close-icon:hover {
    transform: scale(1.05);
    opacity: 0.8;
}

.MessagePreview-close-icon:active {
    transform: scale(0.95);
}
