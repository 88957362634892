.SettingsPageBase-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background: #f3f3f3;
}

.SettingsPageBase-content {
    display: flex;
    flex-grow: 1;
    max-width: 750px;
    width: 100%;
    flex-direction: column;
    align-self: center;
    box-sizing: border-box;
    padding: 0 10px;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.SettingsPageBase-content::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}
