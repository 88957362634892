.MessageInputRecording-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: center;
    max-width: 650px;
    min-width: 0;
    height: 40px;
    padding: 10px;
    background-color: #aeb7c2;
    border-radius: 25px;
    align-items: center;
}

.MessageInputRecording-red-dot {
    box-sizing: border-box;
    background-color: red;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.MessageInputRecording-duration {
    color: white;
    font-weight: 500;
    margin-right: 5px;
}

.MessageInputRecording-stop {
    font-size: 25px;
    color: white;
    cursor: pointer;
}
