@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.ChatHeader-container {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    height: 35px;
    width: 100%;
    background-color: white;
    padding: 20px 0;
    cursor: pointer;
}

.ChatHeader-container.admin {
    cursor: auto;
}

.ChatHeader-content-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 36px;
    min-width: 0;
    overflow: hidden;
    flex-grow: 0;
}
