.NoFriends-container {
    display: flex;
    flex-direction: row;
    padding: 24px;
}

.NoFriends-icon-wrapper {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin: 0 15px 0 0;
    flex-grow: 0;
    object-fit: cover;
    flex-shrink: 0;
}

.NoFriends-text-div {
    display: flex;
    flex-direction: column;
}

.NoFriends-container-title {
    color: white;
    margin-bottom: 6px;
}

.NoFriends-description-span {
    color: #77767d;
    font-size: 14px;
    font-weight: 400;
}
