.SendButton-button {
    background-color: #97a6f2;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.SendButton-button:hover {
    background-color: #8b9cf2;
    cursor: pointer;
}

.SendButton-button:active {
    background-color: #97a6f2;
}

.SendButton-button.disabled {
    pointer-events: none;
    opacity: 0.25;
}
