.ModalButton-div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border-color: #97a6f2;
    border-width: 2px;
    border-style: solid;
    height: 40px;
    min-width: 200px;
    margin-top: 12px;
    cursor: pointer;
}

.ModalButton-div:hover {
    background-color: #97a6f2;
}

.ModalButton-span {
    font-weight: 500;
    color: #97a6f2;
    font-size: 16px;
    padding: 10px 20px;
    text-align: center;
    white-space: pre-wrap;
    word-break: break-word;
}

.ModalButton-div:hover > .ModalButton-span-hovered {
    color: white;
}
