@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.Explore-container {
    background-color: white;
    display: flex;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.Explore-container::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}

.Explore-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}
