@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.BasicModal-backdrop {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.BasicModal-layout {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
