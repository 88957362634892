@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.ChatExtraButtons-container {
    display: flex;
    flex-direction: row;
    background-color: white;
    border-radius: 12px;
    max-width: 650px;
    align-self: center;
    width: 100%;
    margin: 15px 10px;
}
