.ChatProfilePicture-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    align-self: center;
    margin-top: 40px;
    height: 140px;
}

.ChatProfilePicture-img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
}

.ChatProfilePicture-img.avatar-exists {
    cursor: pointer;
}
