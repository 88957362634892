.MessageImage-img-div {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
}

.MessageImage-img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    border-radius: 12px;
    cursor: pointer;
}

.MessageImage-img.blur {
    filter: blur(15px);
}

.MessageImage-caption {
    margin-left: 10px;
    padding: 0 5px;
}

.MessageImage-nsfw-eye-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.MessageImage-nsfw-eye-icon {
    color: #97a6f2;
    opacity: 0.5;
    font-size: 40px;
}
