.GenderFilter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0 0 0;
    width: 100%;
}

.GenderFilter-title-span {
    color: #201f24;
    font-weight: 500;
}
