.Toggle-container {
    display: flex;
    position: relative;
    border-radius: 32px;
    background-color: #f3f3f3;
    height: 20px;
    width: 32px;
    box-sizing: border-box;
    padding: 3px 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px 0;
    cursor: pointer;
    transition: 0.4s;
}

.Toggle-container.checked {
    background-color: #97a6f2;
}

.Toggle-slider {
    position: absolute;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: #77767d;
    transition: 0.4s;
}

.Toggle-slider.checked {
    transform: translateX(10px);
    background-color: white;
}
