.PopoverMenu-backdrop {
    background-color: rgba(0, 0, 0, 0);
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.PopoverMenu-container {
    position: fixed;
    z-index: 10;
    background-color: white;
    list-style: none;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
    min-width: 150px;
    max-width: 50%;
    overflow: hidden;
}

.PopoverMenu-icon {
    font-size: 20px;
    color: #97a6f2;
}
