.UploadingImagePreview-container {
    display: flex;
    margin-top: 15px;
    max-width: 650px;
    align-self: center;
    width: 100%;
}

.UploadingImagePreview-img {
    border-radius: 5px;
    object-fit: contain;
}

.UploadingImagePreview-cross-div {
    position: relative;
    right: 25px;
    top: 5px;
    opacity: 0.5;
    height: 20px;
}

.UploadingImagePreview-cross-div:hover {
    opacity: 0.8;
}
