.ChatProfileMenuItem-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0;
    min-height: 100px;
    cursor: pointer;
    padding: 5px;
    box-sizing: border-box;
}

.ChatProfileMenuItem-title {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    white-space: pre-wrap;
    word-break: break-word;
    text-align: center;
    justify-content: center;
    align-items: flex-start;
    margin-top: 8px;
}

.ChatProfileMenuItem-border {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #f3f3f3;
}

.ChatProfileMenuItem-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 800px) {
    .ChatProfileMenuItem-title {
        display: none;
    }
}
