.ImageCropModal-backdrop {
    /* Use !important to override original modal background color */
    background-color: rgba(0, 0, 0, 0.75) !important;
}

.ImageCropModal-container {
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ImageCropModal-img {
    object-fit: contain;
    /* Use !important to override original image height and width */
    max-height: 80vh !important;
    max-width: 80vw !important;
}
