.BasicButton-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #97a6f2;
    color: white;
    height: 50px;
    border-radius: 12px;
    border-width: 0;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    padding: 0 30px;
    max-width: 80%;
}

.BasicButton-button:hover {
    background-color: #8b9cf2;
    cursor: pointer;
}

.BasicButton:active {
    transform: translateY(1px);
}
