.SettingsItemCopeable-title {
    font-size: 16px;
    color: black;
}

.SettingsItemCopeable-icon {
    color: black;
    font-size: 20px;
}

.SettingsItemCopeable-icon:hover {
    transform: scale(1.05);
}

.SettingsItemCopeable-icon:active {
    transform: scale(0.95);
}
