.SettingsAvatarModalFooter-container {
    display: flex;
    flex-direction: row;
    margin-top: 5vh;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: firebrick;
}

.SettingsAvatarModalFooter-span {
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    color: white;
}
