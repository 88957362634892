.MessageVoice-container {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: min(300px, 80vw);
    flex-grow: 1;
    padding: 15px 0;
}

.MessageVoice-play {
    height: 52px;
    width: 52px;
    border-radius: 50%;
    padding-right: 15px;
    cursor: pointer;
}

.MessageVoice-play.user {
    color: white;
}

.MessageVoice-play.partner {
    color: #97a6f2;
}

.MessageVoice-play.not-ready {
    cursor: not-allowed;
}

.MessageVoice-waveform {
    height: 30px;
    width: 100%;
    z-index: 0;
}

.MessageVoice-duration {
    color: #93919d;
    font-size: 14px;
    position: absolute;
    bottom: 5px;
    left: 52px;
}

.MessageVoice-duration.partner {
    color: #93919d;
}

.MessageVoice-duration.user {
    color: white;
}
