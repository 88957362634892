.ChatProfile-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1 1 auto;
    background-color: #f3f3f3;
}

.ChatProfile-content {
    display: flex;
    flex-grow: 1;
    max-width: 750px;
    width: 100%;
    flex-direction: column;
    align-self: center;
    box-sizing: border-box;
    padding: 0 10px;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.ChatProfile-content::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}
