.MessageEditedLabel-span {
    font-size: 12px;
    margin-right: 2px;
}

.MessageEditedLabel-span.user {
    color: #cbd3f9;
}

.MessageEditedLabel-span.partner {
    color: #93919d;
}
