.SearchAnimation-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 150px;
    transform: translateY(0px);
}

.SearchAnimation-circle {
    height: 15px;
    width: 15px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #bfbec3;
    transform: translateY(-20px);
    animation-duration: 1250ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
}

.SearchAnimation-circle.first {
    animation-name: first;
}

.SearchAnimation-circle.second {
    animation-name: second;
}

.SearchAnimation-circle.third {
    animation-name: third;
}

@keyframes first {
    0% {
        height: 15px;
        transform: translateY(-20px);
        border-radius: 50%;
    }
    50% {
        height: 40px;
        transform: translateY(0px);
        border-radius: 30%;
    }
    65% {
        height: 15px;
        transform: translateY(0px);
        border-radius: 50%;
    }
    80% {
        height: 15px;
        transform: translateY(0px);
        border-radius: 50%;
    }
    100% {
        height: 15px;
        transform: translateY(-20px);
        border-radius: 50%;
    }
}

@keyframes second {
    0% {
        height: 15px;
        transform: translateY(-20px);
        border-radius: 50%;
    }
    50% {
        height: 15px;
        transform: translateY(0px);
        border-radius: 50%;
    }
    65% {
        height: 40px;
        transform: translateY(0px);
        border-radius: 30%;
    }
    80% {
        height: 15px;
        transform: translateY(0px);
        border-radius: 50%;
    }
    100% {
        height: 15px;
        transform: translateY(-20px);
        border-radius: 50%;
    }
}

@keyframes third {
    0% {
        height: 15px;
        transform: translateY(-20px);
        border-radius: 50%;
    }
    50% {
        height: 15px;
        transform: translateY(0px);
        border-radius: 50%;
    }
    65% {
        height: 15px;
        transform: translateY(0px);
        border-radius: 50%;
    }
    80% {
        height: 40px;
        transform: translateY(0px);
        border-radius: 30%;
    }
    100% {
        height: 15px;
        transform: translateY(-20px);
        border-radius: 50%;
    }
}
