@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.Username-container {
}

.Username-text {
    margin: 0 5px 0 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
