.SettingsSelectPicker-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    max-width: 650px;
    width: 100%;
    background-color: white;
    margin: 20px 10px;
    border-radius: 12px;
}
