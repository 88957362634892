.ImageCropModalFooter-container {
    display: flex;
    flex-direction: row;
    margin-top: 5vh;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #201f24;
}

.ImageCropModalFooter-button-common {
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    color: white;
}

.ImageCropModalFooter-button-cancel {
    border-right-style: solid;
    border-right-color: #77767d;
    border-right-width: 1px;
}

.ImageCropModalFooter-button-save {
}
