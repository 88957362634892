.BackButton-container {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 20px 10px;
}

.BackButton-title {
    font-size: 16px;
    margin-left: 8px;
    font-weight: 400;
}
