@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

html,
body {
    height: 100%;
    margin: 0;
    background-color: #f3f3f3;
}

#root {
    height: 100%;
}

p,
span,
button,
h5,
li {
    font-family: 'Roboto', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    white-space: pre-wrap;
    word-break: break-word;
}
