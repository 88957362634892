@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.UserSettingsEditableSection-container {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    min-width: 0;
}

.UserSettingsEditableSection-content {
    display: flex;
    flex-direction: row;
}

.UserSettingsEditableSection-title {
    font-size: 12px;
    font-weight: 500;
    color: #97a6f2;
}

.UserSettingsEditableSection-input-wrapper {
    flex-grow: 1;
    position: relative;
    max-width: 90%;
}

.UserSettingsEditableSection-input {
    flex-grow: 1;
    background-color: white;
    min-height: 50px;
    border-radius: 12px;
    padding: 15px;
    box-sizing: border-box;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px 0;
    font-family: 'Roboto', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    text-align: start;
    color: #201f24;
    overflow-x: scroll;
    white-space: pre-line;
    word-break: break-all;
}
.UserSettingsEditableSection-input.limit-exceeded {
    background-color: rgba(255, 0, 0, 0.2);
}

[contenteditable='true']:empty:before {
    content: attr(data-placeholder);
    pointer-events: none;
    display: block; /* For Firefox */
    color: #93919d;
}

.UserSettingsEditableSection-input:focus {
    outline: none;
}

.UserSettingsEditableSection-bottom-tip {
    font-size: 12px;
    color: #77767d;
}
