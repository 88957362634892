.DeletedChat-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.DeletedChat-title {
    font-size: 24px;
    font-weight: 500;
    margin: 35px 0 12px 0;
    color: #201f24;
    text-align: center;
}

.DeletedChat-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #97a6f2;
    color: white;
    padding: 20px;
    border-radius: 12px;
    border-width: 0;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
}

.DeletedChat-button:hover {
    background-color: #8b9cf2;
}

.DeletedChat-button:active {
    transform: translateY(1px);
}
