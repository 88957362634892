.ExplorePreview-container {
    align-items: center;
    justify-content: flex-start;
}

.ExplorePreview-icon-div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2d2b31;
}

.ExplorePreview-border-div {
    border-top-width: 1px;
    border-top-color: #2d2b31;
    border-top-style: solid;
    margin-left: 80px;
}

.ExplorePreview-username-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
}

.ExplorePreview-img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin: 0 15px 0 0;
    flex-grow: 0;
    object-fit: cover;
}
