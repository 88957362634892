.SettingsSelectPickerItem-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    cursor: pointer;
    width: 100%;
}

.SettingsSelectPickerItem-container.selected {
    background-color: #97a6f2;
    border-radius: 12px;
}

.SettingsSelectPickerItem-container.border {
    border-bottom: #f3f3f3 solid 1px;
}

.SettingsSelectPickerItem-span {
    font-size: 16px;
    font-weight: 500;
    color: #201f24;
}

.SettingsSelectPickerItem-span.selected {
    color: white;
}
