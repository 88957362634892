.EmojiPanel-container {
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;
    overflow-y: scroll;
    position: fixed;
    background: white;
    bottom: 85px;
    border-radius: 8px;
    max-height: 30%;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px 0;
}

.EmojiPanel-emoji-container {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 10px;
    cursor: pointer;
}

.EmojiPanel-emoji-container:hover {
    background: #f3f3f3;
}
