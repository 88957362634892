.PopOverOption-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    box-sizing: border-box;
    padding: 15px;
    min-width: 0;
}

.PopOverOption-container.disabled {
    opacity: 0.25;
    pointer-events: none;
    cursor: crosshair;
}

.PopOverOption-container:hover {
    cursor: pointer;
    background-color: #f3f3f3;
}

.PopOverOption-span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PopOverOption-icon {
    margin-right: 10px;
}
