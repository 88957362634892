.EmojiButton-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    width: 52px;
    height: 52px;
    flex-shrink: 0;
    cursor: pointer;
}
